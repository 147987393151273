import styled from 'styled-components';

export const PerifericosContainer = styled.div`
  margin-top: 16px;

  .style-row:hover td {
    background-color: #f5f5f5 !important;
  }

  .data-grid {
    .hideRightSeparator .MuiDataGrid-columnSeparator {
      display: none !important;
    }
  }

  .MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
    background-color: #8850bf !important;
    color: #fff !important;
  }
`;
