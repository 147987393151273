import React, { useCallback } from 'react';
import ToggleDefault from '~/components/ToggleDefault';
import ContaBanco from '~/components/ContaBanco';
import { useRegraLoja } from '~/pages/RegraDeLoja/RegraLojaContext';

const Pdvfinanceiro = () => {
  const {
    formRegraLoja,
    changeFormRegraLoja,
    formTela: {
      setValue,
      formState: { errors },
    },
  } = useRegraLoja();

  const handleChangeContaBanco = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setValue('cod_cc_abertura', val);
      changeFormRegraLoja({
        ...formRegraLoja,
        cod_cc_abertura: {
          ...formRegraLoja.cod_cc_abertura,
          value: val,
          isInvalid,
        },
      });
    },
    [changeFormRegraLoja, setValue],
  );
  return (
    <>
      <div className="row mt-5">
        <div className="col-sm-8 col-md-4">
          <ToggleDefault
            labelText="Não Confere Tesouraria?"
            setChecked={formRegraLoja.flg_ignora_tes.value}
            onSwitch={() => {
              setValue('flg_ignora_tes', !formRegraLoja.flg_ignora_tes.value);
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_ignora_tes: {
                  ...formRegraLoja.flg_ignora_tes,
                  value: !formRegraLoja.flg_ignora_tes.value,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-8 col-md-4">
          <ToggleDefault
            labelText="Tesouraria sem Turnos?"
            setChecked={formRegraLoja.flg_tes_sem_turno.value}
            onSwitch={() => {
              setValue(
                'flg_tes_sem_turno',
                !formRegraLoja.flg_tes_sem_turno.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_tes_sem_turno: {
                  ...formRegraLoja.flg_tes_sem_turno,
                  value: !formRegraLoja.flg_tes_sem_turno.value,
                },
              });
            }}
          />
        </div>
        <div className="col-sm-8 col-md-4">
          <ToggleDefault
            labelText="Tesousaria sem Bloqueio por Caixas não Liberados?"
            setChecked={formRegraLoja.flg_tes_sem_bloq.value}
            onSwitch={() => {
              setValue(
                'flg_tes_sem_bloq',
                !formRegraLoja.flg_tes_sem_bloq.value,
              );
              changeFormRegraLoja({
                ...formRegraLoja,
                flg_tes_sem_bloq: {
                  ...formRegraLoja.flg_tes_sem_bloq,
                  value: !formRegraLoja.flg_tes_sem_bloq.value,
                },
              });
            }}
          />
        </div>
      </div>
      <div className="col-sm-8 col-md-4 mt-5">
        <ContaBanco
          label="Conta Fundo de Abertura"
          value={formRegraLoja.cod_cc_abertura.value}
          isRequired
          setInvalid={!!errors.cod_cc_abertura}
          onChange={(newValue: any) => {
            handleChangeContaBanco(
              newValue,
              typeof newValue.value !== 'number',
            );
          }}
          loja={formRegraLoja.cod_loja.value.value}
        />
      </div>
    </>
  );
};

export default Pdvfinanceiro;
