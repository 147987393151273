import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Etiqueta from './Abas/Etiqueta';
import Balanca from './Abas/Balanca';
import { useRegraLoja } from '../../RegraLojaContext';
import { PerifericosContainer } from './styles';

const Perifericos = () => {
  const { abaPeriferico, changeAbaPeriferico } = useRegraLoja();

  return (
    <PerifericosContainer>
      <Tabs
        id="controlled-perifericos"
        activeKey={abaPeriferico}
        onSelect={(k) => changeAbaPeriferico(k)}
      >
        <Tab eventKey="etiquetas" title="Etiquetas">
          <Etiqueta />
        </Tab>

        <Tab eventKey="balancas" title="Balanças">
          <Balanca />
        </Tab>
      </Tabs>
    </PerifericosContainer>
  );
};

export default Perifericos;
