/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import { DataGrid, GridColDef } from '@material-ui/data-grid';
import { useRegraLoja } from '~/pages/RegraDeLoja/RegraLojaContext';

import { IFormEtiqueta, IEtiqueta } from '~/pages/RegraDeLoja/types';
import { v4 } from 'uuid';
import ToggleDefault from '~/components/ToggleDefault';

import { addUpdate } from '~/utils/masterDetail';

const Etiqueta = () => {
  const [etiquetas, setEtiquetas] = useState<IEtiqueta[]>([]);
  const {
    formRegraLoja,

    masterDetail,
    setMasterDetail,
  } = useRegraLoja();

  useEffect(() => {
    (async () => {
      const regs = formRegraLoja.Etiquetas.etiquetas.map(
        (item: IFormEtiqueta) => {
          return {
            uuid: v4(),
            id: v4(),
            cod_parametro_etiqueta: item.cod_parametro_etiqueta.value,
            des_parametro: item.des_parametro.value,
            nome_pc: item.nome_pc.value,
            tipo_porta: item.porta.value.value,
            tipo_linguagem: item.linguagem.value.value,
            val_temperatura: item.temperatura.value,
            tipo_dpi: item.dpi.value.value,
            val_margem_esquerda: item.margem_esquerda.value,
            val_velocidade: item.velocidade.value,
            tipo_pagina_codigo: item.pagina_codigo.value.value,
            flg_inativo: item.flg_inativo.value,
          };
        },
      );

      await addUpdate('etiquetas', regs, masterDetail, setMasterDetail);
      setEtiquetas(regs);
    })();
  }, [formRegraLoja.Etiquetas.etiquetas]);

  const handleInativarEtiqueta = async (uuid: string) => {
    const etiquetaInativar = etiquetas.map((etiqueta: any) => {
      if (etiqueta.uuid === uuid) {
        etiqueta.flg_inativo = !etiqueta.flg_inativo;
      }

      return etiqueta;
    });

    await addUpdate(
      'etiquetas',
      etiquetaInativar,
      masterDetail,
      setMasterDetail,
    );

    setEtiquetas(etiquetaInativar);
  };

  const etiquetaColumns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'id',
      hide: true,
      minWidth: 230,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_parametro',
      headerName: 'Nome Impressora',
      minWidth: 230,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'nome_pc',
      headerName: 'Nome Computador',
      width: 230,
      flex: 3,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: '',
      headerName: 'Inativo?',
      sortable: false,
      width: 180,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      disableColumnMenu: true,
      headerClassName: 'hideRightSeparator',
      renderCell: (params: { row: any }) => {
        const { row } = params;
        const onInativar = () => {
          handleInativarEtiqueta(row.uuid);
        };

        return (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              boxSizing: 'content-box',
            }}
          >
            <ToggleDefault
              labelText=""
              setChecked={row.flg_inativo}
              onSwitch={() => {
                onInativar();
              }}
              toggleTitle={
                row.flg_inativo ? 'Clique para ativar' : 'Clique para inativar'
              }
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="row">
        <div
          className="col-sm-12 col-md-12"
          style={{ height: 380, width: '100%', marginTop: '16px' }}
        >
          <DataGrid
            className="data-grid"
            rows={etiquetas}
            columns={etiquetaColumns}
            pageSize={5}
            localeText={{
              noRowsLabel: 'Nenhum registro encontrado',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar colunas',
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            padding: '10px',
            justifyContent: 'flex-end',
          }}
        >
          <a
            href="https://konvix.zendesk.com/hc/pt-br/articles/28943754945563-Integrador#h_01J73PMEXG06M3YJ5A3G74RVC7"
            target="_blank"
            className="linkMaterial"
          >
            Como configurar uma impressora de etiquetas?
          </a>
        </div>
      </div>
    </>
  );
};

export default Etiqueta;
