import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import { v4 } from 'uuid';

import { ColumnSeparator } from '~/components/NovosInputs/CustomDataGrid/styles';

interface GridColDefCustomProps {
  field: string;
  headerName?: string | undefined;
  width?: number | string;
  editable?: boolean;
  headerAlign?: string;
  align?: string;
  hideRightSeparator?: boolean;
  format?: string;
  renderCell?: (row: any) => void;
  fixed?: boolean;
}

interface FormProps {
  rows: any[];
  columns: GridColDefCustomProps[];
  hasTitle?: boolean;
  minHeight?: string;
  maxHeight?: string;
  errorMessage?: string;
  totalizador?: boolean;
}

const CustomDataGrid: React.FC<FormProps> = (props) => {
  const {
    rows,
    columns,
    minHeight = '400px',
    maxHeight = '400px',
    errorMessage = 'Nenhum registro encontrado',
  } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer
        style={{
          height: '100%',
          maxHeight,
          minHeight: minHeight === '0rem' ? '300px' : minHeight,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: `${rows.length > 0 ? 'space-between' : ''}`,
        }}
      >
        <Table stickyHeader>
          <TableHead style={{ position: 'sticky', top: '0', zIndex: '2' }}>
            <TableRow style={{ display: 'flex' }}>
              {columns.map((column) => {
                if (column.fixed) {
                  return (
                    <TableCell
                      key={column.field}
                      style={{
                        position: 'sticky',
                        right: 0,
                        width: column.width,
                        minWidth: column.width,
                        zIndex: 9999,
                        display: 'flex',
                        padding: '0px',
                      }}
                    >
                      <>
                        {!column.hideRightSeparator && (
                          <div
                            style={{
                              width: '10%',
                              display: 'flex',
                              alignContent: 'center',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                            }}
                          >
                            <svg
                              className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                              focusable="false"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path d="M11 19V5h2v14z" />
                            </svg>
                          </div>
                        )}
                        <div
                          style={{
                            width: '90%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {column.headerName}
                        </div>
                      </>
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={v4()}
                    style={{
                      minWidth: `${column.width}`,
                      display: 'flex',
                      justifyContent: column.headerAlign,
                    }}
                  >
                    {column.headerName}
                    {!column.hideRightSeparator && (
                      <ColumnSeparator>
                        <svg
                          className="MuiSvgIcon-root MuiDataGrid-iconSeparator"
                          focusable="false"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M11 19V5h2v14z" />
                        </svg>
                      </ColumnSeparator>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody id="bodyGrid">
            {rows.length > 0 &&
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      id="rowGrid"
                      style={{
                        display: 'flex',
                        backgroundColor: '#ffffff',
                      }}
                      hover
                      className="style-row"
                      tabIndex={-1}
                      key={v4()}
                    >
                      {columns.map((column) => {
                        if (column.fixed) {
                          return (
                            <TableCell
                              key={column.field}
                              style={{
                                position: 'sticky',
                                right: 0,
                                width: column.width,
                                minWidth: column.width,
                                backgroundColor: '#ffffff',

                                zIndex: 0,
                              }}
                            >
                              {column.renderCell
                                ? column.renderCell(row)
                                : row[column.field]}
                            </TableCell>
                          );
                        }

                        return (
                          <TableCell
                            style={{
                              display: 'flex',
                              minWidth: `${column.width}`,
                              justifyContent: column.align,
                            }}
                            key={v4()}
                          >
                            {column.renderCell
                              ? column.renderCell(row)
                              : row[column.field]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>

        {rows.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '10px',
            }}
          >
            <p>{errorMessage}</p>{' '}
          </div>
        )}
      </TableContainer>

      <TablePagination
        id="pagination"
        component="div"
        rowsPerPageOptions={[10, 20, 30]}
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default CustomDataGrid;
